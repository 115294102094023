import React, { useEffect, useState } from "react";
import { checkQuestionAlreadyLiked, appendOrCreateQuestionAnsweredCookie } from '../../lib/helpers'
import { makeStyles } from "@material-ui/core/styles";
import liked from "./img/liked.svg";
import nonLiked from "./img/non-liked.svg";
import * as styles from "./question-likes.module.css";
import {
  Typography
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({


}));

function QuestionLikes(props) {
  const {
    dashboardId,
    dictionary
  } = props;

  const [likes, setLikes] = useState(0);
  const [likedImgClassName, setLikedImgClassName] = useState(styles.hidden);
  const [nonLikedImgClassName, setNonLikedImgClassName] = useState(styles.hidden);
  const [likesContainerClassName, setLikesContainerClassName] = useState(styles.nonClickable);
  const [questionLiked, setQuestionLiked] = useState(false);

  useEffect(() => {
    var questionAlreadyLiked = checkQuestionAlreadyLiked(props.dashboardId);

    if (questionAlreadyLiked) {
      setLikedState();
    } else {
      setNonLikedState();
    }

    fetch(dictionary.nodes.find(x => x.key === 'get_likes_api_endpoint').value, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(props.dashboardId)
    })
      .then(response => {
        return response.json()
      })
      .then(data => { setLikes(data.NumberOfLikes); })
      .catch(error => console.log('error', error));
  }, []);

  useEffect(() => {
    if (questionLiked && window.dataLayer) {
      window.dataLayer.push({ event: 'question_liked' });
    }
  }, [questionLiked]);

  const classes = useStyles();

  function handleClick(e) {
    e.preventDefault();

    var questionAlreadyLiked = checkQuestionAlreadyLiked(dashboardId);

    if (questionAlreadyLiked) {
      return false;
    }

    var likeModel = {
      QuestionId: dashboardId,
      UserId: dictionary.nodes.find(x => x.key === 'empty_guid').value
    }

    fetch(dictionary.nodes.find(x => x.key === 'like_question_endpoint').value, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(likeModel)
    })
      .then(response => {
        setQuestionLiked(true);
        setLikedState();

        appendOrCreateQuestionAnsweredCookie('liked-questions', dashboardId, 365000);

        setLikes(likes + 1);

        return response;
      })
      .catch(error => console.log('error', error));
  }

  function setLikedState() {
    setLikedImgClassName(styles.visible);
    setNonLikedImgClassName(styles.hidden);
    setLikesContainerClassName(styles.nonClickable);
  }

  function setNonLikedState() {
    setLikedImgClassName(styles.hidden);
    setNonLikedImgClassName(styles.visible);
    setLikesContainerClassName(styles.clickable);
  }

  return (
    <div onClick={handleClick} className={`${likesContainerClassName} ${classes.likesContainer} ${styles.likesContainer}`}>
      <a href="#" className={styles.noTextDecoration}>
        <img src={liked} className={`${styles.likeImage} ${likedImgClassName}`} />
        <img src={nonLiked} className={`${styles.likeImage} ${nonLikedImgClassName}`} />
        <Typography variant="body2" className={styles.likesLabel}>
          {likes}
        </Typography>
      </a>
    </div>
  );
}

export default QuestionLikes;
