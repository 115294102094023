import React from "react";
import { Typography, Button, Menu, MenuItem, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Figure } from "./Figure";
import ButtonIcon from "./icon/ArrowRightSquare.svg";
import { AddTrailingSlashToUrl } from "../lib/helpers";


const useStyles = makeStyles((theme) => ({
  adviserProfile: {
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    border: '1px solid #DBE3EF',
    boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
    width: '100%',
    marginBottom: "1.5em",
    [theme.breakpoints.up("md")]: {
      marginRight: "30px",
      marginTop: "39px"
    },

  },
  figureConstraint: {
    minWidth: "55px",
    minHeight: "55px",
    maxWidth: "55px",
    maxHeight: "55px",
    marginRight: "10px"
  },
  nameText: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  nameTextgrey: {
    color: "#8C8CA1",
    fontSize: "14px",
    fontWeight: 'normal',
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  summary: {
    //styleName: Desktop/Body Text Small;
    fontSize: "14px",
    fontWeight: 'normal',
    lineHeight: "20px",
    letterSpacing: "0",
    marginTop: "16px",
  },
  answeredBy: {
    marginBottom: "16px"
  },
  caption: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "0px",
    alignSelf: "stretch",
  },
  noWrap: {
    flexWrap: "nowrap"
  },
  viewProfileButtonText: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px'
  },
  viewProfileButton: {
    width: '100%',
    boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    padding: '1em',
    color: '#262641',
    marginTop: '1em'
  },
  viewProfileButtonImage: {
    marginLeft: '.4em'
  },
  viewProfileAnchor: {
    width: '100%',
    textDecoration: 'none'
  }
}));

export default function AdviserProfile(props) {
  const classes = useStyles();
  const data = props.adviser.people;
  //console.log('adviser: ', props.adviser);

  return (
    <Grid className={classes.adviserProfile}>
      <Typography variant="h4" className={classes.answeredBy}>Answered by</Typography>
      <Grid container item direction="row" className={classes.noWrap}>
        <Grid className={classes.figureConstraint}>
          <Figure node={data.image} isAdviserImage={true} isAdviserFilteredImage={true} />
        </Grid>
        <Grid className={classes.caption}>
          <Typography variant="body1" className={classes.nameText}>{data.name}</Typography>
          <Typography variant="body1" className={classes.nameTextgrey}>{data.jobTitle}</Typography>
        </Grid>
      </Grid>
      <Typography variant="body2" className={classes.summary}>{data.summary && data.summary[0].children[0].text}</Typography>
      <Grid container>
        <a href={'/advisers/' + AddTrailingSlashToUrl(props.adviser.people.slug.current)} className={classes.viewProfileAnchor}>
          <Button className={classes.viewProfileButton}>
            <Typography
              variant="button"
              className={classes.viewProfileButtonText}
            >
              {props.dictionary.nodes.find(x => x.key === 'ask_question_adviser_view_profile_button_text').value}
          </Typography>
            <img className={classes.viewProfileButtonImage} src={ButtonIcon} alt="ButtonIcon" />
          </Button>
        </a>
      </Grid>
    </Grid>
  );
}
