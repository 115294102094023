import { graphql } from "gatsby";
import QuestionPost from "../components/question-post";
import React from "react";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import { getPageUrlOutOfSanityReference, toPlainText } from "../lib/helpers";

export const query = graphql`
  query QuestionPostTemplateQuery($id: String!) {
    post: sanityQuestionPost(id: { eq: $id }) {
      id
      publishedAt
      dashboardId
      categories {
        _id
        title
      }
      mainImage {
        ...SanityImage
        alt
      }
      title
      subtitle
      template
      slug {
        current
      }
      Widgets {
        ... fragSanityWidgetCategory
      }
      _rawExcerpt(resolveReferences: { maxDepth: 8 })
      _rawBody(resolveReferences: { maxDepth: 8 })
      adviser {
        _key
        people {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
          jobTitle
          averageRating
          numberOfLikes
          summary {
            children {
              text
              marks
            }
          }
          slug {
            current
          }
        }
      }
      _rawAnswer(resolveReferences: { maxDepth: 8 })
      readerFirstName
    }
    dictionary: allSanityDictionary {
     nodes {
        ... dictionaryFragment
      }
    }
  }
`;

const QuestionPostTemplate = (props) => {
  const { data, errors } = props;

  const post = data && data.post;
  const getPageUrl = getPageUrlOutOfSanityReference(post)

  post.dictionary = data && data.dictionary;

  try {
    return (
      <Layout>
        {errors && <SEO title="GraphQL Error" />}
        {post && (
          <SEO
            title={post.title || "Untitled"}
            description={toPlainText(post._rawExcerpt)}
            image={post.mainImage}
            canonical={getPageUrl}
          />
        )}

        {errors && (
          <Container>
            <GraphQLErrorList errors={errors} />
          </Container>
        )}

        {post && <QuestionPost {...post} />}
      </Layout>
    );
  } catch (e) {
    console.log("QuestionPostTemplate", e);
    return (null);
  }

};

export default QuestionPostTemplate;
