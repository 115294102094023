// extracted by mini-css-extract-plugin
export var bigtitle = "blog-post-module--bigtitle--mXOkP";
export var categories = "blog-post-module--categories--AH481";
export var categoriesHeadline = "blog-post-module--categoriesHeadline--aWVrh typography-module--base--FphVn";
export var fundHeaderBackground = "blog-post-module--fundHeaderBackground--j0Lp-";
export var fundHeaderBackgroundMobile = "blog-post-module--fundHeaderBackgroundMobile--yC9Se";
export var mainContent = "blog-post-module--mainContent--eLO5g";
export var mainImage = "blog-post-module--mainImage--IMMYf";
export var metaContent = "blog-post-module--metaContent--nKBLn";
export var publishedAt = "blog-post-module--publishedAt---ggFA typography-module--small--KHf9L";
export var root = "blog-post-module--root--wx77T";
export var title = "blog-post-module--title--9dCoJ typography-module--responsiveTitle1--0t0jA";