import * as styles from "./blog-post.module.css";
import { useEffect, useMemo, useState } from "react"
import { differenceInDays, formatDistance, format } from "date-fns";
import PeopleList from "./people-list";
import ContainerForPages from "./containerForPages";
import Container from "./container";
import PortableText from "./portableText";
import QuestionLikes from "./questionLikes/question-likes";
import QuestionLikesMobile from "./questionLikes/question-likes-mobile";
import AdviserProfile from "./question-post-adviser-profile";
import React from "react";
import FilteredQuestions from "../components/filteredQuestions/filtered-questions";
import AskQuestionWidget from "../components/askQuestionWidget";
import FooterDisclaimer from "../components/footer-disclaimer";
import AuthenticatorNoRedirect from "../components/auth0/AuthenticatorNoRedirect";

import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import SideBar from "./sideBar";
import {
  AppBar,
  Grid,
  Typography,
  Toolbar,
  ListItem,
  List,
  IconButton,
  Hidden,
  Drawer,
  CssBaseline,
  Button,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";
import { MainImage } from "gatsby-plugin-image";

import WidgetComponent from "./sidebarComponents/widgetComponent";

const useStyles = makeStyles((theme) => ({
  mainImage: {
    borderRadius: "12px",
  },
  linkTextNotActive: {
    color: "#50586C",
    fontSize: "14px",
    fontWeight: "500",
  },
  linkTextActive: {
    color: "#262641",
    fontSize: "14px",
    fontWeight: "600",
  },
  authorMargin: {
    marginTop: "31px",
  },
  dateMargin: {
    color: "#4A4A68",
    "@media (max-width:1280px)": {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)'
    },

  },
  grey: {
    // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000px' height='1000px'%3E%3Cdefs%3E%3ClinearGradient id='g1'%3E%3Cstop stop-color='%239796F0'/%3E%3Cstop offset='1' stop-color='%23FBC7D4'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ccircle cx='500' cy='500' r='400' style='fill-opacity: .1;' fill='url(%23g1)'/%3E%3C/svg%3E")`,
    // backgroundRepeat: "repeat-y",
    // backgroundSize: "1000px 1000px",
    // backgroundPosition: "top center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      backgroundSize: "contain",
    },
  },
  modalBackground: {
    // backdropFilter: "blur(100px)",
    maxWidth: "100%",
    paddingTop: "14px",
    paddingBottom: "60px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  seventyFive: {
    width: "75%",
  },
  twentyFive: {
    width: "25%",
    justifyContent: "flex-end",
    "@media (max-width:1280px)": {
      width: "50%",
      justifyContent: "flex-start",
      position: 'relative'
    },
  },
  marginFromTitle: {
    marginTop: "32px",
    marginBottom: "0px",
  },
  h3: {
    paddingBottom: "16px",
  },
  divider: {
    marginBottom: "32px",
    marginTop: "32px",
    backgroundColor: "#DBE3EF",
  },
  dot: {
    color: "#B2C2C3",
    marginLeft: "7px",
    marginRight: "7px",
  },
  sidebar: {
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      width: '100%'
    },
  },
  content: {
    width: "calc(100% - 370px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: "20px",
    },
  },
  navMenu: {
    paddingTop: "20px",
    position: "sticky",
    top: "72px",
    marginTop: "42px",
    marginBottom: '72px',
    zIndex: "22",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: '100%'
    },
  },
  bodyContainer: {
    maxWidth: "1170px",
  },
  navMenuContainer: {
    height: "100%",
    minWidth: "5px",
    position: "sticky"
  },
  adContent: {
    width: "100%",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: "20px",
    },
  },
  likesMobile: {
    width: '50%',
    justifyContent: 'flex-end'
  },
  disclaimerFlexContainer:{
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
  breadcrumbHolder:{
    paddingBottom: "24px",
  },
}));

function BlogPost(props) {
  const [loading, setLoading] = useState(true);
  const [widgetsReady, setWidgetsReady] = useState(false);
  const [showWidget, setshowWidget] = useState(false)

  const {
    dashboardId,
    dictionary,
    _rawBody,
    _rawAnswer,
    adviser,
    categories,
    title,
    subtitle,
    template,
    mainImage,
    publishedAt,
    readerFirstName,
    Widgets,
    brazeCustomAttributes,
    brazeCustomAttributesSB
  } = props;

  var catsList = "";
  for (var i = 0; i < categories.length; i++) {
    catsList += categories[i].title;
  }
  var styleContent = styles.mainContent;
  var styleBar = styles.metaContent;

  let direction;

  if (template == "C-SB") {
    styleContent = styles.contentLeft;
    styleBar = styles.sidebarRight;
    direction = "row";
  }

  if (template == "SB-C") {
    styleContent = styles.contentRight;
    styleBar = styles.sidebarLeft;
    direction = "row-reverse";
  }
  const classes = useStyles();

  var showGate = false;
  console.log('showWidget', showWidget)
  // let showWidget = false

  // Evaluate whether to display widgetComponent
  // if (showGate === true) {
  //   showWidget = false
  // }

  // if (showGate === false) {
  //   showWidget = true
  // }

  useMemo(() => {
    if (typeof window !== "undefined") {
      setshowWidget(AuthenticatorNoRedirect(brazeCustomAttributes))
    }
  }, [showWidget])

  useEffect(() => {
    if (typeof window !== "undefined") {
      setshowWidget(AuthenticatorNoRedirect(brazeCustomAttributes))
      console.log('showGate if statement', showWidget)
    }
    setWidgetsReady(true)
    // setLoading(false);
  }, [showWidget]);

  // if (typeof window !== "undefined") {
  //   showGate = AuthenticatorNoRedirect(brazeCustomAttributes);
  // }

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     showGate = AuthenticatorNoRedirect(brazeCustomAttributes);
  //     console.log('showGate if statement', showGate)
  //   }
  //   setWidgetsReady(true)
  //   // setLoading(false);
  // }, [showGate]);

  console.log('showGate', showGate)
  console.log('brazeCustomAttributesSB', brazeCustomAttributesSB)

  return (
    <article className={classes.grey}>
      <ContainerForPages>
        <Grid container item justify="center">
          <Grid item xs={0} md={1} className={classes.navMenuContainer}>

          </Grid>
          <Hidden only={["xs", "sm", "md"]} implementation="js">
            <nav className={classes.navMenu}>
              <QuestionLikes
                dashboardId={dashboardId}
                dictionary={dictionary}
              />
            </nav>
          </Hidden>
          <Grid item xs={11} md={10} className={classes.bodyContainer}>
            <Grid
              container
              className={classes.modalBackground}
              direction={direction}
            >
              <Grid item className={classes.content}>
                <Hidden smDown implementation="css">
                  <div className={classes.breadcrumbHolder}>
                  <Typography variant="subtitle2">
                    <Link
                      to="/"
                      style={{ textDecoration: "none" }}
                      className={classes.linkTextNotActive}
                    >
                      Home
                    </Link>
                    <span className={classes.dot}>{" • "}</span>
                    <Link
                      to="/advice"
                      style={{ textDecoration: "none" }}
                      className={classes.linkTextNotActive}
                    >
                      Advice
                    </Link>
                    <span className={classes.dot}>{" • "}</span>
                    <Link
                      to="/ask"
                      style={{ textDecoration: "none" }}
                      className={classes.linkTextNotActive}
                    >
                      Common questions
                    </Link>
                  </Typography>
                </div>
                </Hidden>

                <Typography variant="h1" className={styles.bigtitle}>
                  {title}
                </Typography>
                {/* <p className={styles.bigtitle}>{subtitle}</p> */}
                <Grid
                  container
                  item
                  alignItems="stretch"
                  justify="space-between"
                  className={classes.marginFromTitle}
                >
                  <Grid
                    container
                    item
                    className={classes.seventyFive}
                    alignItems="flex-start"
                    justify="flex-start"
                  ></Grid>

                  <Grid
                    container
                    item
                    className={classes.twentyFive}
                    alignItems="flex-start"
                  >
                    {publishedAt && (
                      <Typography
                        variant="body1"
                        className={classes.dateMargin}
                      >
                        {differenceInDays(new Date(publishedAt), new Date()) > 3
                          ? formatDistance(new Date(publishedAt), new Date())
                          : format(new Date(publishedAt), "dd MMMM yyyy")}
                      </Typography>
                    )}

                  </Grid>
                  <Hidden only={["xl", "lg"]} implementation="js">
                    <Grid
                      item
                      className={classes.likesMobile}>
                      <QuestionLikesMobile
                        dashboardId={dashboardId}
                        dictionary={dictionary}
                      />
                    </Grid>
                  </Hidden>

                </Grid>
                <div>
               {/*   <AskQuestionWidget dictionary={dictionary} />*/}
                </div>
                {/* no main image, only as a display in root */}
                {/* {mainImage && mainImage.asset && (
              <div className={styles.mainImage}>
                <img
                  className={classes.mainImage}
                  src={imageUrlFor(buildImageObj(mainImage))
                    .width(1200)
                    .height(Math.floor((9 / 16) * 1200))
                    .fit("crop")
                    .auto("format")
                    .url()}
                  alt={mainImage.alt}
                />
              </div>
             )} */}

                {/* {authors && <PeopleList items={authors} title="Authors" />} */}
                <Typography variant="h3" className={classes.h3}>
                  Question by {readerFirstName}
                </Typography>
                {_rawBody && <PortableText blocks={_rawBody} />}
                <Divider className={classes.divider} />
                <Typography variant="h3" className={classes.h3}>
                  {
                    dictionary.nodes.find(
                      (x) => x.key === "ask_question_adviser_summary_title"
                    ).value
                  }{" "}


                  {adviser.length > 0 && adviser[0].people !=null && adviser[0].people.name}
                </Typography>
                {_rawAnswer && <PortableText blocks={_rawAnswer} />}
                {/* {categories && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Category</h3>

                <ul>
                  {categories.map((category) => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
             )}*/}
              </Grid>

              <Grid item sm={0} className={classes.sidebar}>


                {adviser.length > 0 && adviser[0].people != null && <AdviserProfile adviser={adviser[0]} dictionary={dictionary} />}
                { Widgets && widgetsReady && <WidgetComponent widgets={Widgets} showGate={showWidget} brazeCustomAttributesSB={brazeCustomAttributesSB}/> }

                {/* {widgets && (
              <SideBar
                title="Sidebar"
                categoryList={catsList}
                widgets={widgets}
              />
            )} */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} md={1}></Grid>
        </Grid>
        <Grid item className={classes.adContent}>
          <WidgetComponent widgets={Widgets} target="bottom" />

        </Grid>
        <Grid className={classes.disclaimerFlexContainer} md={10} xs={11} item justify="center">
          <FooterDisclaimer />
        </Grid>

      </ContainerForPages>
    </article>
  );
}

export default BlogPost;
