import React, { useEffect, useState } from "react";
import * as styles from "./filtered-questions.module.css";
import liked from "./img/liked.svg";
import likedMobile from "./img/likedSmall.svg";
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { makeStyles } from "@material-ui/core/styles";
import Container from "../container";
import { AddTrailingSlashToSlug } from "../../lib/helpers";


import {
  Grid,
  Hidden
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .Mui-selected': {
      background: '#3C465F;',
      color: 'white',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      height: '3.5em',
      width: '3.5em',
      borderRadius: '1.75em',
      [theme.breakpoints.down("sm")]: {
        height: '3em',
        width: '3em',
        borderRadius: '1.5em',
        fontSize: '9px'
      },
    },
    '& .MuiPaginationItem-ellipsis': {
      paddingTop: '.8em'
    },
    '& .MuiPaginationItem-icon': {
      fontSize: '1.5rem'
    },
    '& .MuiPaginationItem-root': {
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial !important'
      }
    },
    '& ul > li > button:not(.Mui-selected),.MuiPaginationItem-ellipsis': {
      backgroundColor: 'white',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#2F2F4E',
      height: '3.5em',
      width: '3.5em',
      borderRadius: '1.75em',
      [theme.breakpoints.down("sm")]: {
        height: '3em',
        width: '3em',
        borderRadius: '1.5em',
        fontSize: '9px'
      },
    }
  },

}));

var dummyQuestions = [];

function FilteredQuestions(props) {
  const {
    questionCat
  } = props;

  var questionCatUppercase = "";
  if (questionCat) {
    questionCatUppercase = questionCat[0].toUpperCase() + questionCat.slice(1);
  }


  const [getQuestionCat, setQuestionCat] = useState('')


  const [ageBracketOptions, setAgeBracketOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [age, setAge] = useState('');
  const [product, setProduct] = useState('');
  const [sort, setSort] = useState('');
  const [ageLabel, setAgeLabel] = useState('All ages');
  const [productLabel, setProductLabel] = useState('All products');
  const [sortLabel, setSortLabel] = useState('Sort');
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(2);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [currentSelection, setCurrentSelection] = useState({});
  const [analyticsSelection, setAnalyticsSelection] = useState({});

  var apiURL = process.env.GATSBY_QUESTIONAPI;

  const sortOptions = [
    {
      Label: 'Sort',
      Value: 'PublishedDate'
    },
    {
      Label: 'Most recent',
      Value: 'PublishedDate'
    },
    {
      Label: 'Likes',
      Value: 'Likes'
    }
  ];

  useEffect(() => {
    (async () => {
      if (questions.length === 0) {
        setTotalRows(15);
        setQuestions(dummyQuestions);
      }

      setItemsPerPage(10);
      setQuestionCat(questionCat)
      await getDropdownOptions();
    })();
  }, []);

  useEffect(() => {
    (async () => {


      await searchAgPageLoad(currentSelection);
    })();
  }, [currentSelection]);

  useEffect(() => {
    (async () => {
      if (JSON.stringify(analyticsSelection) === '{}') {
        return;
      }

      var combinedObject = {
        Age: ageLabel,
        Product: productLabel,
        Sort: sortLabel
      };
      if (window.dataLayer) {
        window.dataLayer.push({ 'filtered_questions_combined': JSON.stringify(combinedObject) });

        window.dataLayer.push({ event: 'filtered_questions_search' });
      }
    })();
  }, [analyticsSelection]);

  var getCategories = async function () {
    return fetch(apiURL + '/QuestionCategories/GetAllQuestionCategories', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((responseJson) => { return responseJson });
  }

  var getQuestions = async function (model) {
    return fetch(apiURL + '/FilteredQuestions/FilteredQuestions', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    })
      .then((response) => response.json())
      .then((responseJson) => { return responseJson });
  }

  var getDropdownOptions = async function () {
    var ageBracketsOptionsResult = await getAgeBrackets();
    var categoriesOptionsResult = await getCategories();

    setCategoryOptions(categoriesOptionsResult);
    setAgeBracketOptions(ageBracketsOptionsResult);

    if ((getQuestionCat != "") && (getQuestionCat != null) && (typeof getQuestionCat != "undefined")) {
      var cat = getQuestionCat[0].toUpperCase() + getQuestionCat.slice(1);
    }
    else {
      cat = "All products";
    }


    var ageValue = ageBracketsOptionsResult.filter(function (item) { return item.Label === 'All ages'; })[0].Value;
    var categoryValue = categoriesOptionsResult.filter(function (item) { return item.Label === cat; })[0].Value;
    var sortValue = sortOptions.filter(function (item) { return item.Label === 'Sort'; })[0].Value;


    setAge(ageValue);
    setProduct(categoryValue);
    setSort(sortValue);

    setCurrentSelection({
      AgeValue: '',
      CategoryValue: categoryValue,
      SortValue: 'PublishedDate'
    });
  }

  var getAgeBrackets = async function () {
    return fetch(apiURL + '/AgeBracketFilterOptions/GetAgeBracketFilterOptions', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((responseJson) => { return responseJson });
  }

  var handleSearch = async function () {
    setAnalyticsSelection({
      AgeValue: ageLabel,
      CategoryValue: productLabel,
      SortValue: sortLabel
    });

    setPageOffset(0);

    await search(1);
  }

  var ageChange = function (event) {
    setAgeLabel(event.target.selectedOptions[0].label);
    setAge(event.target.value);
  }

  var productChange = function (event) {


    setProductLabel(event.target.selectedOptions[0].label);
    setProduct(event.target.value);
  }

  var sortChange = function (event) {
    setSortLabel(event.target.selectedOptions[0].label);
    setSort(event.target.value);
  }

  const handlePageChange = async (event, value) => {
    await search(value);
  };

  var searchAgPageLoad = async function (initialDropdownOptions) {
    var categoryValue = '';

    if ((getQuestionCat != "") && (getQuestionCat != null) && (typeof getQuestionCat != "undefined")) {
      var cat = getQuestionCat[0].toLowerCase() + getQuestionCat.slice(1).toLowerCase();

      var categoriesOptionsResult = await getCategories();

      try {
        categoryValue = categoriesOptionsResult.filter(function (item) { return item.Label.toLowerCase() === cat.toLowerCase(); })[0].Value;

        
      } catch (e) {
        console.log(e)
      }
      


    }



    setProductLabel(getQuestionCat);
    setProduct(categoryValue);



    var model = {
      Categories: categoryValue,
      AgeBrackets: '',
      OrderBy: 'PublishedDate',
      Offset: 0,
      ItemsPerPage: itemsPerPage
    }

    var questions = await getQuestions(model);

    if (questions.length > 0) {
      setTotalRows(questions[0].TotalRows);
      setTotalPages(Math.ceil(questions[0].TotalRows / itemsPerPage));
      setQuestions(questions);
    }
  }

  var search = async function (newPageNumber) {


    setQuestionCat("");


    setPage(newPageNumber);

    var newPageOffset = 0;

    if (totalRows <= itemsPerPage || newPageNumber === 1) {
      newPageOffset = 0;
    }
    else {
      newPageOffset = (newPageNumber - 1) * itemsPerPage;
    }

    setPageOffset(newPageOffset);

    var model = {
      Categories: product,
      AgeBrackets: age,
      OrderBy: sort,
      Offset: newPageOffset,
      ItemsPerPage: itemsPerPage
    }

    var questions = await getQuestions(model);

    if (questions.length > 0) {
      setTotalRows(questions[0].TotalRows);
      setTotalPages(Math.ceil(questions[0].TotalRows / itemsPerPage));
      setQuestions(questions);
    }
  }

  const classes = useStyles();

  return (
    <>
      <Container>
        <Grid container className={classes.grey}>
          <div className={styles.filteredQuestionsContainer}>
            <div className={styles.filtersContainer}>
              <Grid container spacing={2} className={styles.filteredQuestionsGrid}>
                <Grid item xs={12} md>
                  <select className={styles.filterDropdown} id='product-dropdown' onChange={productChange}>

                   
                    {categoryOptions.map((categoryOption) => (
                      <option value={categoryOption.Value} selected={categoryOption.Label.toLowerCase() == questionCatUppercase.toLowerCase() ? 'true' : ''}>
                        {categoryOption.Label}
                      </option>
                    ))}
                  </select>
                </Grid>
                <Grid item xs={12} md>
                  <select className={styles.filterDropdown} id='age-dropdown' onChange={ageChange}>

                    {ageBracketOptions.map((ageBracketOption) => (
                      <option value={ageBracketOption.Value}>
                        {ageBracketOption.Label}
                      </option>
                    ))}
                  </select>
                </Grid>
                <Grid item xs={12} md>
                  <select className={styles.filterDropdown} id='sort-dropdown' onChange={sortChange}>
                    {sortOptions.map((sortOption) => (
                      <option value={sortOption.Value}>{sortOption.Label}</option>
                    ))}
                  </select>
                </Grid>
                <Grid item xs={12} md={3}>
                  <span className={styles.searchButton} id='search-button' onClick={handleSearch}>
                    SEARCH
            </span>
                </Grid>
              </Grid>
            </div>
            <div>
              {questions.map((question) => (
                <Grid container className={styles.questionContainer} key={question}>
                  <Grid item md={11} xs={12} className={styles.questionInfoContainer}>
                    <Grid container>
                      <Grid item xs={12} className={styles.questionTitleContainer}>
                        <a
                          href={"/ask/" + AddTrailingSlashToSlug(question.Slug)}
                          className={styles.questionAnchor}
                        >
                          <label className={styles.questionTitle}>
                            {question.Title}
                          </label>
                        </a>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container direction="row" alignItems="center">
                          <Grid item className={styles.publishedDateContainer}>
                            <label className={styles.publishedDate}>
                              {question.PublishedDate}
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={9}
                            className={styles.categoriesContainer}
                          >
                            <Grid container alignItems="center">
                              {question.Categories.split(",").map((category) => {
                                if (category!="") {
                                  return <Grid item className={styles.categoryTextContainer}>
                                    <label className={styles.categoryText}>
                                      {category}
                                    </label>
                                  </Grid>
                                }
                                else {
                                  return null;
                                }
                              }
                              )}
                              {question.AgeBracket &&
                                <Grid item className={styles.categoryTextContainer}>
                                  <label className={styles.categoryText}>
                                    {question.AgeBracket}
                                  </label>
                                </Grid>
                              }

                              <Hidden mdUp implementation="js">
                                <Grid item className={styles.categoryTextContainerForLikes}>
                                  <label className={styles.categoryTextForLikes}>
                                    <label className={styles.likeText}>
                                      {question.Likes}
                                    </label>
                                    <img src={likedMobile} className={styles.likeImg} />
                                  </label>
                                </Grid>
                              </Hidden>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Hidden smDown implementation="js">
                    <Grid item md={1} xs={12} className={styles.likesContainer}>
                      <Grid container>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6} className={styles.likesImgContainer}>
                          <Grid container alignItems="center" justify="center">
                            <Grid item className={styles.likeImgContainer}>
                              <img src={liked} />
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center" justify="center">
                            <Grid item>
                              <label className={styles.likeText}>
                                {question.Likes}
                              </label>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Hidden>
                </Grid>
              ))}
            </div>
            <Grid
              container
              alignItems="center"
              justify="center"
              className={styles.paginationContainer}
            >
              <Grid item>
                <Pagination
                  count={totalPages}
                  className={classes.root}
                  page={page}
                  onChange={handlePageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      classes={{ selected: classes.selected }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Container>


    </>
  );
}

export default FilteredQuestions;
